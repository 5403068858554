
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93xKxcyhuVLmGrkKlE4V79qZzaVl4KEdgfO_45o74efiKP4Meta } from "/app/pages/dns/[id].vue?macro=true";
import { default as securite1iw1m9JtlULOaqBYiZMcAQXg5aHYqSu8W5g16_ZTdSAMeta } from "/app/pages/securite.vue?macro=true";
import { default as dashboardMP4s_45_45Pnrs3lAogdEWxQSVgBglFzEw4XrIvkluYCTzEMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as index66d_45qe93UkmuK2ib86vLd_Czlj363ml8wiB4jMWyV28Meta } from "/app/pages/dns/index.vue?macro=true";
import { default as _91id_93tb8_bn6ezDtaOBYIY4rYhI08wM0HRoivlF0qKWs8vb8Meta } from "/app/pages/menu/[id].vue?macro=true";
import { default as indexyA_45cRcxSnEeBQ6iEOuWQs6cmt5sExXGSklgLmTJAvuEMeta } from "/app/pages/avis/index.vue?macro=true";
import { default as indexFRoDQ19m6Hd_STaLVjEuB4spupLJvu5bL2PuNg7CYgUMeta } from "/app/pages/file/index.vue?macro=true";
import { default as formations4d5x8ICNp6I2qGxxFLZzO6yD7ZEwlkjwsAKbCCfFWLcMeta } from "/app/pages/formations.vue?macro=true";
import { default as indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta } from "/app/pages/menu/index.vue?macro=true";
import { default as _91id_93g9UdYX9cj29uQW8AODs5OMlcqPaMwWkPTfbzGRNYXCYMeta } from "/app/pages/roles/[id].vue?macro=true";
import { default as _91id_93TI3C61KgW6dqAjE7_458LERoTUfKIXqirvTcuz17jHCfkMeta } from "/app/pages/users/[id].vue?macro=true";
import { default as indexle0Wx1_45XTIDiH64543eCKIsgSfoZxKvuUYMgwRqVkHUMeta } from "/app/pages/roles/index.vue?macro=true";
import { default as suggestions65KLdwRkm8fU34p4RpZmxYekIlnS55hMUk6o8_lksZwMeta } from "/app/pages/suggestions.vue?macro=true";
import { default as indexwqqxPBLNl0Gqxy2G7X4gCBzRids0_eE4yk9P0pmntIkMeta } from "/app/pages/users/index.vue?macro=true";
import { default as indexfL7W5S90JXS6rCKfRbOS14e9C5srKBQe8wpJTyyIi_454Meta } from "/app/pages/action/index.vue?macro=true";
import { default as editions_45fdruiHkL_dN_PAUnj9mpB2iF9fnAYuJVt5qAZe3ysoRh4AMeta } from "/app/pages/editions-fdr.vue?macro=true";
import { default as _91id_932rRvqfA_45fzN_453hj_45KP6uN87BvpZ2vhYtMmdLCRov6VMMeta } from "/app/pages/modules/[id].vue?macro=true";
import { default as indexBmWPbAsBVfE0mP9cxGliLc_45dCb6_45vZWHvjr67eNTWygMeta } from "/app/pages/profil/index.vue?macro=true";
import { default as _91id_93sjNEGo9rPuPUu6UzKR6sQJAIBxS93Jsr_vnKDYDBTNAMeta } from "/app/pages/adhesion/[id].vue?macro=true";
import { default as indexL5LwjUzWpLo6eTwBaO1qq4R59VpICst3_6FZWGqutJ8Meta } from "/app/pages/fdr/fdr/index.vue?macro=true";
import { default as indexlYcu4N9sv4FGZHQeTpP6SjPSlylyOGF2nS3PYfXaWVkMeta } from "/app/pages/modules/index.vue?macro=true";
import { default as _91id_93jg6u468q5P4IgFSkS_CzrjEi0OIJ7cLc_9drxtXhHCEMeta } from "/app/pages/adherents/[id].vue?macro=true";
import { default as index0mYhzkVq750_QlI_45CduMK7fqNo83xoYLw_1mNBP4xTQMeta } from "/app/pages/adhesion/index.vue?macro=true";
import { default as indexWJmVfx3Ktk6eMCN7KumHroijOa_45sykrt7YAJ7tNTNPsMeta } from "/app/pages/affilies/index.vue?macro=true";
import { default as _91id_93UAYm4phQ86hw_hkEBxYMgQGYaDRNJbSIo2Wgt9_45xA_45gMeta } from "/app/pages/fdr/regle/[id].vue?macro=true";
import { default as fiches_45conseilUJQYOGS9t3RWQhimwz49z5TOrcD_45G8RjLiJpscwqrywMeta } from "/app/pages/fiches-conseil.vue?macro=true";
import { default as index8Z6cRjiLKsFSIpsGxNITujjEc7_453V4se5MciCKOJn9sMeta } from "/app/pages/menu/add/index.vue?macro=true";
import { default as indexyamhl0wGj7h0OUvZf48XLG1L3YBKP6iUdj9ycXy74rUMeta } from "/app/pages/register/index.vue?macro=true";
import { default as indexT8S_45cnpIPYd_45LRLfx1l81vG2j0MdtJ2y_bBVHr7fi6kMeta } from "/app/pages/registre/index.vue?macro=true";
import { default as indexnGh5Caf7HxTTz6WB_hWIzgm8zoghDCc1Un6ek7oTEWUMeta } from "/app/pages/adherents/index.vue?macro=true";
import { default as _91id_93571wW_45XqDl3f9MkFtoLZKYHssMHvVXFlflDQRfcSj4UMeta } from "/app/pages/forgot-pwd/[id].vue?macro=true";
import { default as indexs_45g4YAya_OBpvoyAEqPd__45_45u4fpsnItbM_45SvpokoQpgMeta } from "/app/pages/liste-rdv/index.vue?macro=true";
import { default as indexYWR7LMrrfJHIbXmNaSxEWv3buFlliZ2mgBmIJCrXo0kMeta } from "/app/pages/roles/add/index.vue?macro=true";
import { default as index8V5Wo2ipHTDtMmqWa7Drv6E8JtKabbimgnKXlZHLAk8Meta } from "/app/pages/signature/index.vue?macro=true";
import { default as indexjj2xQvPuivDGkca34HYH1WXJlkkPT_e_45unez3guntRcMeta } from "/app/pages/forgot-pwd/index.vue?macro=true";
import { default as indexi7sd9xTiOOfZwRfYJTMq7ZndwdLXVXdz2CpvkDQDZEMMeta } from "/app/pages/affectation/index.vue?macro=true";
import { default as index6vcCoj63g3isFgmwOWGzs7x_45siPXsdX0drpLSy78OeoMeta } from "/app/pages/edition-fdr/index.vue?macro=true";
import { default as _91id_93w6lSMzk1cpfxidbGVq9h8ZEHOMrMDQVOZ_xQbgoTA_cMeta } from "/app/pages/fdr/read/fdr/[id].vue?macro=true";
import { default as indexQM_uc8BN35oLhoorSqJCWDzP5i7XZMRGNWTBH1dWCAoMeta } from "/app/pages/modules/add/index.vue?macro=true";
import { default as _91id_93e6hvjskPFN673LDwrPjvUCYbdWDLnniET0FSr5_C7WgMeta } from "/app/pages/reclamations/[id].vue?macro=true";
import { default as indexGrJ86SFfVGyp3Aw_zcJRTC9og7UaVN7dk4_45cr_qsOM4Meta } from "/app/pages/avis-affilie/index.vue?macro=true";
import { default as indexh3f47lLqEfIqmR4GtjJ2f73nkqK9oeQvsoseVUfXBGoMeta } from "/app/pages/dns/fichiers/index.vue?macro=true";
import { default as indexd5S925r_45bmq8tAQcVfD64kvyX0mqwDqN_uuAhvOqA2sMeta } from "/app/pages/dns/relances/index.vue?macro=true";
import { default as index1khgDFirL3vZJjOcA3FM_KD6Yb73C1ALVephOJ5jdhYMeta } from "/app/pages/fdr/conseils/index.vue?macro=true";
import { default as indexY1vtlCREmygNpJGAvqZElSpzurfwBhpGVFc4dYnmJxUMeta } from "/app/pages/planning/vmo/index.vue?macro=true";
import { default as indexrira1g_45LZJSQH__7kFEDdMkTJHTmpvnZCf155hhyFC4Meta } from "/app/pages/reclamations/index.vue?macro=true";
import { default as reglement_45adherent6xr7xby0BORSwLSdl8t7SNWU6jnHedaXpnhYtNjkDq0Meta } from "/app/pages/reglement-adherent.vue?macro=true";
import { default as indexHx_SvmbjZVY0S9Cf0FElv3iWBhthiRKLS9wPH_4fRMkMeta } from "/app/pages/users/create/index.vue?macro=true";
import { default as indexJDeLBwmBpjF0Ovsjiup1ersmmgvOgheYXib4oBesFzEMeta } from "/app/pages/dns/paiements/index.vue?macro=true";
import { default as _91id_93aHEMU8jEduLHkisvhsiDWMkhFel844cxJogc7vP_4_8Meta } from "/app/pages/fdr/create/fdr/[id].vue?macro=true";
import { default as _91id_93HxlDOnakV4580T5201YLh8HJm6WkJmKSCW_kSD0rLusMeta } from "/app/pages/fdr/read/boite/[id].vue?macro=true";
import { default as indexnkUd8HEb8oCpl04rQjvr_45nVF1wEVNOJtfKfuPcf3YL8Meta } from "/app/pages/notifications/index.vue?macro=true";
import { default as _91id_93m9zZKgTjJ8ZKDhutr4DWKPqXlmadhYD0gm7WeEfA1h8Meta } from "/app/pages/planning/conge/[id].vue?macro=true";
import { default as _91id_93aMrZZ4_45HsayOIYdoOuHdeM0e6cpZKMWNGml_1WwPtjMMeta } from "/app/pages/planning/garde/[id].vue?macro=true";
import { default as indexbgXA16FAILcQSxuMXcuth_yn6pne1Xu_45PEw4p21i3soMeta } from "/app/pages/planning/vets/index.vue?macro=true";
import { default as _91id_93oQnRHK7qaUcfnlm4HN2aqrxwXTx09xi9ilhQ_450rqKOUMeta } from "/app/pages/user-adherents/[id].vue?macro=true";
import { default as _91id_930KXX3l_45jJ32S_45AMG_45YxtQXdtzlkXwZZ8Ek0TPdTE2jAMeta } from "/app/pages/affectation/add/[id].vue?macro=true";
import { default as _91id_93Xg_q7m6EwO5iNmmMKdxJXDqDSfRwKaogJlr1QByhksoMeta } from "/app/pages/fdr/point/boite/[id].vue?macro=true";
import { default as indexgZSOYgy4DomP5eTYDNIB5spwxTfQgcQgdSSniq_qFUQMeta } from "/app/pages/fdr/simulation/index.vue?macro=true";
import { default as _91id_934ONV_45Ol1Gu_AQyO1Qh50iUst_0QeAI_45NbS9yGlgmqz0Meta } from "/app/pages/fdr/thematiques/[id].vue?macro=true";
import { default as index2d_456O6ZVFswWXtK4M66SZv542kIfGnxNPJC1rCZYGi0Meta } from "/app/pages/planning/conge/index.vue?macro=true";
import { default as index9ptBb_45xlHiNeztq733ZjPYYi3adMZHBZYvvSTc4yzecMeta } from "/app/pages/planning/garde/index.vue?macro=true";
import { default as indexSZiuditJGtlJqvTbJ8jVvlXUD_Wai8JhSCnImGSuj8oMeta } from "/app/pages/rdv-specialist/index.vue?macro=true";
import { default as indexMcv86wmbHYNgWB3Qo6nxux2m4bI5gAGzTMjyk8hR7O4Meta } from "/app/pages/user-adherents/index.vue?macro=true";
import { default as indexR_KP8OpctKihrLuPS_POZlClxekSfAJVyvjtMwjz6I8Meta } from "/app/pages/users/assigner/index.vue?macro=true";
import { default as _91id_93zDSNjRsw6W5hvmiSVdoQS7xQ_45qDlRXyJ_45OWYOvLe8i4Meta } from "/app/pages/adhesion/valides/[id].vue?macro=true";
import { default as _91id_93McqXyam0Y6ZhYQpmxQAb6NHZRpHjlLoWhK_NF4yvYmAMeta } from "/app/pages/affectation/edit/[id].vue?macro=true";
import { default as _91id_935LxIegrMvY93bdeQjJ8Ww8S7tUBG2pezJiqebp7DLzUMeta } from "/app/pages/fdr/create/boite/[id].vue?macro=true";
import { default as _91id_93p3KFUaA0jjohhNAPpuJ5eUjRKJizeXYGKbHGfL_45HsYAMeta } from "/app/pages/fdr/create/regle/[id].vue?macro=true";
import { default as _91id_93pcO70CFvLrywvzH7COzlznCYGtbw0yI4gKDqiBk_45hxEMeta } from "/app/pages/adhesion/clotures/[id].vue?macro=true";
import { default as indexUIo9gJHgsdUQcbLp0DVrVaZ9Kg5bn_45VB42PtQ1TmrCkMeta } from "/app/pages/adhesion/valides/index.vue?macro=true";
import { default as _91idfdr_93L80Gv87OdDQXQ2OGCQc2hWDljbkt5snIBO2MKAXtrboMeta } from "/app/pages/fdr/diagnostic/[idfdr].vue?macro=true";
import { default as indexPktKhjtLdG8BO_CyVlay0ZQ9dgSiS_vwHiyCDXutbhEMeta } from "/app/pages/adhesion/clotures/index.vue?macro=true";
import { default as _91id_93xhslnzT42UpgEQWnJdg7RJ8J5mnodBiffNHRNU21bkwMeta } from "/app/pages/edition-fdr/boites/[id].vue?macro=true";
import { default as _91iddiag_93MotZkJhUBL2dxwcQkZfFiYAhQPtfH6na3EVf9Q4n_6kMeta } from "/app/pages/fdr/read/regle/[iddiag].vue?macro=true";
import { default as index_backupVxpHg5XzkCGkZTB6qUu7FDpXTBiNUviMxN9vGOyG4rcMeta } from "/app/pages/forgot-pwd/index_backup.vue?macro=true";
import { default as _91id_93Tojk99NddSPjYnPJCqcZTFKhnCRhPBJxVJgW_45BHHnp4Meta } from "/app/pages/planning/persuasion/[id].vue?macro=true";
import { default as indexKSGBS0l64J8fW9B_45jkE7ipfkqSiwQq3cK5cKY25X_45C4Meta } from "/app/pages/planning/vembauche/index.vue?macro=true";
import { default as _91id_93PmXFf2lSm7TkpJjr3Y6nOe_45X9FrdQRV4Xagt0s7Or7QMeta } from "/app/pages/adhesion/traitements/[id].vue?macro=true";
import { default as _91idboite_93kA48tIUmq7_45_45Nj4nWt27rE7XXlqvmHBuy6Oe10RClVYMeta } from "/app/pages/fdr/point/point/[idboite].vue?macro=true";
import { default as indexkkw1wu60EnwzN_45YWzvsTcI5JyYBDC7Rwjw_45a_K72kkYMeta } from "/app/pages/planning/formations/index.vue?macro=true";
import { default as indexHnTlGMiUcIvcCVd2jBfgmo_45QFHQ_45LDfa_45C009PMzfOEMeta } from "/app/pages/planning/persuasion/index.vue?macro=true";
import { default as reglement_45interieur_45ostiedy83eL_45dCo_452uBzfzM7yoEbCAJvrAYck4sw9kn6TsCUMeta } from "/app/pages/reglement-interieur-ostie.vue?macro=true";
import { default as indexXlICEcyGA6QOJcUfPwSde0ltlmI5o9YkHjVJkv_450gyQMeta } from "/app/pages/suggestions-affilie/index.vue?macro=true";
import { default as tableau_45bord_45reclamationsX8jzxwOlny6wlUXFkfsyMKx93oBfyj_lve880fqq1_gMeta } from "/app/pages/tableau-bord-reclamations.vue?macro=true";
import { default as indexEBXzYlrJC46zixsU5jTaeM52OCI2RdvT6lwbnZK3JjMMeta } from "/app/pages/adhesion/traitements/index.vue?macro=true";
import { default as fichierFinderO2EWWYGI2BqnBda_It9nWLznoqx9VqGFAy1eVf7T02MMeta } from "/app/pages/dns/fichiers/fichierFinder.vue?macro=true";
import { default as indexMfvBPs8Cpn6gsvzNSOde9_Oe76QyFHbCR5hellgGKc0Meta } from "/app/pages/edition-fdr/articles/index.vue?macro=true";
import { default as indexPFIcHmSiT82_UJKikJkkkM5iJSqmoQ15Cj4B5lOaT2cMeta } from "/app/pages/fdr/point/thematique/index.vue?macro=true";
import { default as _91idthematique_93KKWWM4siIn9Q5SrVRmJi091Y6mcJ_51EFYlaUGp1mbAMeta } from "/app/pages/fdr/tableau/[idthematique].vue?macro=true";
import { default as indexgcCFR7Eo2cFe_ScPuu5urpObhrlzCDmRjl_45x_45fSzedwMeta } from "/app/pages/reclamations/affilie/index.vue?macro=true";
import { default as _91id_93iRkqJfQpDigyeh3KiG8h_45_9Yxs5r1YysgMJiy4xmuxsMeta } from "/app/pages/users/assigner/active/[id].vue?macro=true";
import { default as _91id_93mG5W2ZLrwn87ezbjr9Jpo4iRXcf_4B0Hm9Xpp4j0Ze0Meta } from "/app/pages/avis-embauche-debauche/[id].vue?macro=true";
import { default as indexB2FVtkCUSZNxxoFUiLQSx__4DNAF27RVwaedfuMochIMeta } from "/app/pages/bulletin-consultation/index.vue?macro=true";
import { default as indexenKsZSS0w98QVy1xZovD2pKnzqviP2oy_45mn1e7ejMg0Meta } from "/app/pages/fdr/create/thematique/index.vue?macro=true";
import { default as _91idfdr_93e8Mtb_456IwH_45a9ah_4bY1n6c6oD_45sL2kHxg8vV1YelW4Meta } from "/app/pages/fdr/read/diagnostic/[idfdr].vue?macro=true";
import { default as indexahAEny2f9Zmwn6Sigv9i9lKYl5P2H8E4NEQ54h_npCIMeta } from "/app/pages/avis-embauche-debauche/index.vue?macro=true";
import { default as _91id_93u1T4JuAbPrXnAqpgNydT_M73amUZLO0vQ5qCXGYEARIMeta } from "/app/pages/planning/autres-visites/[id].vue?macro=true";
import { default as _91id_93fUIDUyHW0_gfbZ3YTN0C6HDuKFtEsf0gsGUl7CfsY9MMeta } from "/app/pages/reclamations/archive-in/[id].vue?macro=true";
import { default as _91id_93F9A9_Wb2glqKlGZyEgrL_SwxB7h2ZOk0DCzueDvXvJcMeta } from "/app/pages/edition-fdr/boite-risque/[id].vue?macro=true";
import { default as _91idfdr_93eg7MwtcXib_krgHRE3U0dBrgiCfF8Hof40YHTdPNcxMMeta } from "/app/pages/fdr/create/diagnostic/[idfdr].vue?macro=true";
import { default as indexlc9X9gPU1l4jwKrHs4DGdr1t_TdUbhFv3_9bpDSxynYMeta } from "/app/pages/planning/autres-visites/index.vue?macro=true";
import { default as indexjB_rQ9alDrxPdeIHoYIGCrvIX8fTkPc8OQ4QnciBX6EMeta } from "/app/pages/reclamations/archive-in/index.vue?macro=true";
import { default as indexOKcAPBZueM_rLgJzEu6jONv2yRLmLQSGKOjGxR1bgPQMeta } from "/app/pages/planning/sensibilisation/index.vue?macro=true";
import { default as passwordChangePgc7QUgBXQeWcFlcKxVpf0Twdj9OOH7oZhXe5xbpLI8Meta } from "/app/pages/users/component/passwordChange.vue?macro=true";
import { default as _91id_93YUrPLZdH2cf2_oPZNFwBWdNGtO4Ku_45yVMhOGJxWSaRMMeta } from "/app/pages/adhesion/traitements/files/[id].vue?macro=true";
import { default as _91id_93WYYrn2Q7_45HYShWgBIEPjgZgLM7csXddrcCIBkKma8EUMeta } from "/app/pages/edition-fdr/facteur-risque/[id].vue?macro=true";
import { default as _91id_933hLuCKVRw79BgJ8sRJ7YqTnysf2d_452DGqs3Ln4zMWSoMeta } from "/app/pages/planning/vembauche/traiter/[id].vue?macro=true";
import { default as _91id_93ldANiEKU40Va1F568_45rG8_45Ye1cijqQY82SWqpsLiXkoMeta } from "/app/pages/contenus/reglement-adherent/[id].vue?macro=true";
import { default as index7WkBwpH5AictgYez2x9m7h0BV5qGJ3phSSYj5bd56VgMeta } from "/app/pages/planning/vembauche/traiter/index.vue?macro=true";
import { default as index_40old_41ln_7Ky3nYuKLiP9uqxe4ulgmPdXCgxOa8ZGe2nOH_45XAMeta } from "/app/pages/contenus/reglement-adherent/index(old).vue?macro=true";
import { default as indexiBdJKoE29NSwe8FNOcfatJoxKEaMARoJrVevDE68ZgEMeta } from "/app/pages/contenus/reglement-adherent/index.vue?macro=true";
import { default as indexC3sCYEP_45DoDvlmf0kYrrKfGrmbvSuf7OeYollK6V8ccMeta } from "/app/pages/edition-fdr/articles/create/index.vue?macro=true";
import { default as _91id_93_45RDbNZh6PTNr_45tIPN0IX4O5z5LUKT_rHA1pJzPytTsoMeta } from "/app/pages/planning/accompagnement-appuie/[id].vue?macro=true";
import { default as _91iddiagnostic_93_l_1LhOMEXJ3nc8wqL4JVyHNfL0ljnZsDO7aT6LDaTEMeta } from "/app/pages/fdr/diagnostic/create/[iddiagnostic].vue?macro=true";
import { default as indexkafEcBDKlAUxS6mitPKfk5whn7b7_45o50Scfl19agE4kMeta } from "/app/pages/planning/accompagnement-appuie/index.vue?macro=true";
import { default as _91id_93mMiTOOusnwJlUY1iBsyV9u45P0pxilnW7saaZ19R_45kEMeta } from "/app/pages/edition-fdr/facteur-diagnostique/[id].vue?macro=true";
import { default as _91idthematique_93_45_91idfdr_93IjlByVwgKamQ1_45nlLtrlsNiQ_452mvFNeAle3jB0f19pYMeta } from "/app/pages/fdr/diagnostic/[idthematique]-[idfdr].vue?macro=true";
import { default as _91id_93IE45AZiqW5pHfICaPnjW463_45bSiJHL1JbDpLQvkmpAIMeta } from "/app/pages/contenus/reglement-adherent/poster/[id].vue?macro=true";
import { default as index8pAkQpi7EzGW8qNZJGeWMO4vy0JRpx7fiRNVll5X0DYMeta } from "/app/pages/contenus/reglement-adherent/poster/index.vue?macro=true";
import { default as _91iddiagnostic_938FYOVlz79T5si6chPFDAZO3R_yDCLYh7yIrD_45sRm6n0Meta } from "/app/pages/fdr/diagnostic/resiliation/[iddiagnostic].vue?macro=true";
import { default as _91id_93T_45Z0ypApyBqueom9Tq2zoCCClK8HVsGbt8JdvfyAdFMMeta } from "/app/pages/contenus/reglement-adherent/archive-in/[id].vue?macro=true";
import { default as _91id_93FRK87SUXyF9RzEAIRdVIv9E4lE_45cR9k0U2VpqbFOB9gMeta } from "/app/pages/avis-embauche-debauche/historique-ae-ed/[id].vue?macro=true";
import { default as indexYjVmxffUEHse6W3Bvzu7xCX_UYfibSzKANiw_TzIT_sMeta } from "/app/pages/contenus/reglement-adherent/archive-in/index.vue?macro=true";
import { default as indexT49_45eXl1V7OJo0wGDJ0VlH2hERnhMctbDpTdZhu9ixEMeta } from "/app/pages/avis-embauche-debauche/historique-ae-ed/index.vue?macro=true";
import { default as indexyVmU226mIkncgNPQ5JVsGxnunnFjba9xM_45mSvEVaDLYMeta } from "/app/pages/contenus/reglement-adherent/convocations/index.vue?macro=true";
import { default as indexK2CEiWlqU5M9CKBQ1CMU0soxJsPlGqqHzEhrSfbhoycMeta } from "/app/pages/contenus/reglement-adherent/convocations/new/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "dns-id",
    path: "/dns/:id()",
    meta: _91id_93xKxcyhuVLmGrkKlE4V79qZzaVl4KEdgfO_45o74efiKP4Meta || {},
    component: () => import("/app/pages/dns/[id].vue")
  },
  {
    name: "securite",
    path: "/securite",
    component: () => import("/app/pages/securite.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardMP4s_45_45Pnrs3lAogdEWxQSVgBglFzEw4XrIvkluYCTzEMeta || {},
    component: () => import("/app/pages/dashboard.vue")
  },
  {
    name: "dns",
    path: "/dns",
    meta: index66d_45qe93UkmuK2ib86vLd_Czlj363ml8wiB4jMWyV28Meta || {},
    component: () => import("/app/pages/dns/index.vue")
  },
  {
    name: "menu-id",
    path: "/menu/:id()",
    meta: _91id_93tb8_bn6ezDtaOBYIY4rYhI08wM0HRoivlF0qKWs8vb8Meta || {},
    component: () => import("/app/pages/menu/[id].vue")
  },
  {
    name: "avis",
    path: "/avis",
    meta: indexyA_45cRcxSnEeBQ6iEOuWQs6cmt5sExXGSklgLmTJAvuEMeta || {},
    component: () => import("/app/pages/avis/index.vue")
  },
  {
    name: "file",
    path: "/file",
    meta: indexFRoDQ19m6Hd_STaLVjEuB4spupLJvu5bL2PuNg7CYgUMeta || {},
    component: () => import("/app/pages/file/index.vue")
  },
  {
    name: "formations",
    path: "/formations",
    component: () => import("/app/pages/formations.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta || {},
    component: () => import("/app/pages/menu/index.vue")
  },
  {
    name: "roles-id",
    path: "/roles/:id()",
    meta: _91id_93g9UdYX9cj29uQW8AODs5OMlcqPaMwWkPTfbzGRNYXCYMeta || {},
    component: () => import("/app/pages/roles/[id].vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: _91id_93TI3C61KgW6dqAjE7_458LERoTUfKIXqirvTcuz17jHCfkMeta || {},
    component: () => import("/app/pages/users/[id].vue")
  },
  {
    name: "roles",
    path: "/roles",
    meta: indexle0Wx1_45XTIDiH64543eCKIsgSfoZxKvuUYMgwRqVkHUMeta || {},
    component: () => import("/app/pages/roles/index.vue")
  },
  {
    name: "suggestions",
    path: "/suggestions",
    component: () => import("/app/pages/suggestions.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexwqqxPBLNl0Gqxy2G7X4gCBzRids0_eE4yk9P0pmntIkMeta || {},
    component: () => import("/app/pages/users/index.vue")
  },
  {
    name: "action",
    path: "/action",
    meta: indexfL7W5S90JXS6rCKfRbOS14e9C5srKBQe8wpJTyyIi_454Meta || {},
    component: () => import("/app/pages/action/index.vue")
  },
  {
    name: "editions-fdr",
    path: "/editions-fdr",
    meta: editions_45fdruiHkL_dN_PAUnj9mpB2iF9fnAYuJVt5qAZe3ysoRh4AMeta || {},
    component: () => import("/app/pages/editions-fdr.vue")
  },
  {
    name: "modules-id",
    path: "/modules/:id()",
    meta: _91id_932rRvqfA_45fzN_453hj_45KP6uN87BvpZ2vhYtMmdLCRov6VMMeta || {},
    component: () => import("/app/pages/modules/[id].vue")
  },
  {
    name: "profil",
    path: "/profil",
    meta: indexBmWPbAsBVfE0mP9cxGliLc_45dCb6_45vZWHvjr67eNTWygMeta || {},
    component: () => import("/app/pages/profil/index.vue")
  },
  {
    name: "adhesion-id",
    path: "/adhesion/:id()",
    meta: _91id_93sjNEGo9rPuPUu6UzKR6sQJAIBxS93Jsr_vnKDYDBTNAMeta || {},
    component: () => import("/app/pages/adhesion/[id].vue")
  },
  {
    name: "fdr-fdr",
    path: "/fdr/fdr",
    meta: indexL5LwjUzWpLo6eTwBaO1qq4R59VpICst3_6FZWGqutJ8Meta || {},
    component: () => import("/app/pages/fdr/fdr/index.vue")
  },
  {
    name: "modules",
    path: "/modules",
    meta: indexlYcu4N9sv4FGZHQeTpP6SjPSlylyOGF2nS3PYfXaWVkMeta || {},
    component: () => import("/app/pages/modules/index.vue")
  },
  {
    name: "adherents-id",
    path: "/adherents/:id()",
    meta: _91id_93jg6u468q5P4IgFSkS_CzrjEi0OIJ7cLc_9drxtXhHCEMeta || {},
    component: () => import("/app/pages/adherents/[id].vue")
  },
  {
    name: "adhesion",
    path: "/adhesion",
    meta: index0mYhzkVq750_QlI_45CduMK7fqNo83xoYLw_1mNBP4xTQMeta || {},
    component: () => import("/app/pages/adhesion/index.vue")
  },
  {
    name: "affilies",
    path: "/affilies",
    meta: indexWJmVfx3Ktk6eMCN7KumHroijOa_45sykrt7YAJ7tNTNPsMeta || {},
    component: () => import("/app/pages/affilies/index.vue")
  },
  {
    name: "fdr-regle-id",
    path: "/fdr/regle/:id()",
    meta: _91id_93UAYm4phQ86hw_hkEBxYMgQGYaDRNJbSIo2Wgt9_45xA_45gMeta || {},
    component: () => import("/app/pages/fdr/regle/[id].vue")
  },
  {
    name: "fiches-conseil",
    path: "/fiches-conseil",
    component: () => import("/app/pages/fiches-conseil.vue")
  },
  {
    name: "menu-add",
    path: "/menu/add",
    meta: index8Z6cRjiLKsFSIpsGxNITujjEc7_453V4se5MciCKOJn9sMeta || {},
    component: () => import("/app/pages/menu/add/index.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/app/pages/register/index.vue")
  },
  {
    name: "registre",
    path: "/registre",
    meta: indexT8S_45cnpIPYd_45LRLfx1l81vG2j0MdtJ2y_bBVHr7fi6kMeta || {},
    component: () => import("/app/pages/registre/index.vue")
  },
  {
    name: "adherents",
    path: "/adherents",
    meta: indexnGh5Caf7HxTTz6WB_hWIzgm8zoghDCc1Un6ek7oTEWUMeta || {},
    component: () => import("/app/pages/adherents/index.vue")
  },
  {
    name: "forgot-pwd-id",
    path: "/forgot-pwd/:id()",
    component: () => import("/app/pages/forgot-pwd/[id].vue")
  },
  {
    name: "liste-rdv",
    path: "/liste-rdv",
    meta: indexs_45g4YAya_OBpvoyAEqPd__45_45u4fpsnItbM_45SvpokoQpgMeta || {},
    component: () => import("/app/pages/liste-rdv/index.vue")
  },
  {
    name: "roles-add",
    path: "/roles/add",
    meta: indexYWR7LMrrfJHIbXmNaSxEWv3buFlliZ2mgBmIJCrXo0kMeta || {},
    component: () => import("/app/pages/roles/add/index.vue")
  },
  {
    name: "signature",
    path: "/signature",
    meta: index8V5Wo2ipHTDtMmqWa7Drv6E8JtKabbimgnKXlZHLAk8Meta || {},
    component: () => import("/app/pages/signature/index.vue")
  },
  {
    name: "forgot-pwd",
    path: "/forgot-pwd",
    component: () => import("/app/pages/forgot-pwd/index.vue")
  },
  {
    name: "affectation",
    path: "/affectation",
    component: () => import("/app/pages/affectation/index.vue")
  },
  {
    name: "edition-fdr",
    path: "/edition-fdr",
    meta: index6vcCoj63g3isFgmwOWGzs7x_45siPXsdX0drpLSy78OeoMeta || {},
    component: () => import("/app/pages/edition-fdr/index.vue")
  },
  {
    name: "fdr-read-fdr-id",
    path: "/fdr/read/fdr/:id()",
    meta: _91id_93w6lSMzk1cpfxidbGVq9h8ZEHOMrMDQVOZ_xQbgoTA_cMeta || {},
    component: () => import("/app/pages/fdr/read/fdr/[id].vue")
  },
  {
    name: "modules-add",
    path: "/modules/add",
    meta: indexQM_uc8BN35oLhoorSqJCWDzP5i7XZMRGNWTBH1dWCAoMeta || {},
    component: () => import("/app/pages/modules/add/index.vue")
  },
  {
    name: "reclamations-id",
    path: "/reclamations/:id()",
    meta: _91id_93e6hvjskPFN673LDwrPjvUCYbdWDLnniET0FSr5_C7WgMeta || {},
    component: () => import("/app/pages/reclamations/[id].vue")
  },
  {
    name: "avis-affilie",
    path: "/avis-affilie",
    meta: indexGrJ86SFfVGyp3Aw_zcJRTC9og7UaVN7dk4_45cr_qsOM4Meta || {},
    component: () => import("/app/pages/avis-affilie/index.vue")
  },
  {
    name: "dns-fichiers",
    path: "/dns/fichiers",
    meta: indexh3f47lLqEfIqmR4GtjJ2f73nkqK9oeQvsoseVUfXBGoMeta || {},
    component: () => import("/app/pages/dns/fichiers/index.vue")
  },
  {
    name: "dns-relances",
    path: "/dns/relances",
    meta: indexd5S925r_45bmq8tAQcVfD64kvyX0mqwDqN_uuAhvOqA2sMeta || {},
    component: () => import("/app/pages/dns/relances/index.vue")
  },
  {
    name: "fdr-conseils",
    path: "/fdr/conseils",
    meta: index1khgDFirL3vZJjOcA3FM_KD6Yb73C1ALVephOJ5jdhYMeta || {},
    component: () => import("/app/pages/fdr/conseils/index.vue")
  },
  {
    name: "planning-vmo",
    path: "/planning/vmo",
    meta: indexY1vtlCREmygNpJGAvqZElSpzurfwBhpGVFc4dYnmJxUMeta || {},
    component: () => import("/app/pages/planning/vmo/index.vue")
  },
  {
    name: "reclamations",
    path: "/reclamations",
    meta: indexrira1g_45LZJSQH__7kFEDdMkTJHTmpvnZCf155hhyFC4Meta || {},
    component: () => import("/app/pages/reclamations/index.vue")
  },
  {
    name: "reglement-adherent",
    path: "/reglement-adherent",
    component: () => import("/app/pages/reglement-adherent.vue")
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: indexHx_SvmbjZVY0S9Cf0FElv3iWBhthiRKLS9wPH_4fRMkMeta || {},
    component: () => import("/app/pages/users/create/index.vue")
  },
  {
    name: "dns-paiements",
    path: "/dns/paiements",
    meta: indexJDeLBwmBpjF0Ovsjiup1ersmmgvOgheYXib4oBesFzEMeta || {},
    component: () => import("/app/pages/dns/paiements/index.vue")
  },
  {
    name: "fdr-create-fdr-id",
    path: "/fdr/create/fdr/:id()",
    meta: _91id_93aHEMU8jEduLHkisvhsiDWMkhFel844cxJogc7vP_4_8Meta || {},
    component: () => import("/app/pages/fdr/create/fdr/[id].vue")
  },
  {
    name: "fdr-read-boite-id",
    path: "/fdr/read/boite/:id()",
    meta: _91id_93HxlDOnakV4580T5201YLh8HJm6WkJmKSCW_kSD0rLusMeta || {},
    component: () => import("/app/pages/fdr/read/boite/[id].vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexnkUd8HEb8oCpl04rQjvr_45nVF1wEVNOJtfKfuPcf3YL8Meta || {},
    component: () => import("/app/pages/notifications/index.vue")
  },
  {
    name: "planning-conge-id",
    path: "/planning/conge/:id()",
    component: () => import("/app/pages/planning/conge/[id].vue")
  },
  {
    name: "planning-garde-id",
    path: "/planning/garde/:id()",
    component: () => import("/app/pages/planning/garde/[id].vue")
  },
  {
    name: "planning-vets",
    path: "/planning/vets",
    meta: indexbgXA16FAILcQSxuMXcuth_yn6pne1Xu_45PEw4p21i3soMeta || {},
    component: () => import("/app/pages/planning/vets/index.vue")
  },
  {
    name: "user-adherents-id",
    path: "/user-adherents/:id()",
    component: () => import("/app/pages/user-adherents/[id].vue")
  },
  {
    name: "affectation-add-id",
    path: "/affectation/add/:id()",
    component: () => import("/app/pages/affectation/add/[id].vue")
  },
  {
    name: "fdr-point-boite-id",
    path: "/fdr/point/boite/:id()",
    meta: _91id_93Xg_q7m6EwO5iNmmMKdxJXDqDSfRwKaogJlr1QByhksoMeta || {},
    component: () => import("/app/pages/fdr/point/boite/[id].vue")
  },
  {
    name: "fdr-simulation",
    path: "/fdr/simulation",
    meta: indexgZSOYgy4DomP5eTYDNIB5spwxTfQgcQgdSSniq_qFUQMeta || {},
    component: () => import("/app/pages/fdr/simulation/index.vue")
  },
  {
    name: "fdr-thematiques-id",
    path: "/fdr/thematiques/:id()",
    meta: _91id_934ONV_45Ol1Gu_AQyO1Qh50iUst_0QeAI_45NbS9yGlgmqz0Meta || {},
    component: () => import("/app/pages/fdr/thematiques/[id].vue")
  },
  {
    name: "planning-conge",
    path: "/planning/conge",
    meta: index2d_456O6ZVFswWXtK4M66SZv542kIfGnxNPJC1rCZYGi0Meta || {},
    component: () => import("/app/pages/planning/conge/index.vue")
  },
  {
    name: "planning-garde",
    path: "/planning/garde",
    meta: index9ptBb_45xlHiNeztq733ZjPYYi3adMZHBZYvvSTc4yzecMeta || {},
    component: () => import("/app/pages/planning/garde/index.vue")
  },
  {
    name: "rdv-specialist",
    path: "/rdv-specialist",
    meta: indexSZiuditJGtlJqvTbJ8jVvlXUD_Wai8JhSCnImGSuj8oMeta || {},
    component: () => import("/app/pages/rdv-specialist/index.vue")
  },
  {
    name: "user-adherents",
    path: "/user-adherents",
    meta: indexMcv86wmbHYNgWB3Qo6nxux2m4bI5gAGzTMjyk8hR7O4Meta || {},
    component: () => import("/app/pages/user-adherents/index.vue")
  },
  {
    name: "users-assigner",
    path: "/users/assigner",
    meta: indexR_KP8OpctKihrLuPS_POZlClxekSfAJVyvjtMwjz6I8Meta || {},
    component: () => import("/app/pages/users/assigner/index.vue")
  },
  {
    name: "adhesion-valides-id",
    path: "/adhesion/valides/:id()",
    meta: _91id_93zDSNjRsw6W5hvmiSVdoQS7xQ_45qDlRXyJ_45OWYOvLe8i4Meta || {},
    component: () => import("/app/pages/adhesion/valides/[id].vue")
  },
  {
    name: "affectation-edit-id",
    path: "/affectation/edit/:id()",
    meta: _91id_93McqXyam0Y6ZhYQpmxQAb6NHZRpHjlLoWhK_NF4yvYmAMeta || {},
    component: () => import("/app/pages/affectation/edit/[id].vue")
  },
  {
    name: "fdr-create-boite-id",
    path: "/fdr/create/boite/:id()",
    meta: _91id_935LxIegrMvY93bdeQjJ8Ww8S7tUBG2pezJiqebp7DLzUMeta || {},
    component: () => import("/app/pages/fdr/create/boite/[id].vue")
  },
  {
    name: "fdr-create-regle-id",
    path: "/fdr/create/regle/:id()",
    meta: _91id_93p3KFUaA0jjohhNAPpuJ5eUjRKJizeXYGKbHGfL_45HsYAMeta || {},
    component: () => import("/app/pages/fdr/create/regle/[id].vue")
  },
  {
    name: "adhesion-clotures-id",
    path: "/adhesion/clotures/:id()",
    meta: _91id_93pcO70CFvLrywvzH7COzlznCYGtbw0yI4gKDqiBk_45hxEMeta || {},
    component: () => import("/app/pages/adhesion/clotures/[id].vue")
  },
  {
    name: "adhesion-valides",
    path: "/adhesion/valides",
    meta: indexUIo9gJHgsdUQcbLp0DVrVaZ9Kg5bn_45VB42PtQ1TmrCkMeta || {},
    component: () => import("/app/pages/adhesion/valides/index.vue")
  },
  {
    name: "fdr-diagnostic-idfdr",
    path: "/fdr/diagnostic/:idfdr()",
    meta: _91idfdr_93L80Gv87OdDQXQ2OGCQc2hWDljbkt5snIBO2MKAXtrboMeta || {},
    component: () => import("/app/pages/fdr/diagnostic/[idfdr].vue")
  },
  {
    name: "adhesion-clotures",
    path: "/adhesion/clotures",
    meta: indexPktKhjtLdG8BO_CyVlay0ZQ9dgSiS_vwHiyCDXutbhEMeta || {},
    component: () => import("/app/pages/adhesion/clotures/index.vue")
  },
  {
    name: "edition-fdr-boites-id",
    path: "/edition-fdr/boites/:id()",
    meta: _91id_93xhslnzT42UpgEQWnJdg7RJ8J5mnodBiffNHRNU21bkwMeta || {},
    component: () => import("/app/pages/edition-fdr/boites/[id].vue")
  },
  {
    name: "fdr-read-regle-iddiag",
    path: "/fdr/read/regle/:iddiag()",
    meta: _91iddiag_93MotZkJhUBL2dxwcQkZfFiYAhQPtfH6na3EVf9Q4n_6kMeta || {},
    component: () => import("/app/pages/fdr/read/regle/[iddiag].vue")
  },
  {
    name: "forgot-pwd-index_backup",
    path: "/forgot-pwd/index_backup",
    component: () => import("/app/pages/forgot-pwd/index_backup.vue")
  },
  {
    name: "planning-persuasion-id",
    path: "/planning/persuasion/:id()",
    component: () => import("/app/pages/planning/persuasion/[id].vue")
  },
  {
    name: "planning-vembauche",
    path: "/planning/vembauche",
    meta: indexKSGBS0l64J8fW9B_45jkE7ipfkqSiwQq3cK5cKY25X_45C4Meta || {},
    component: () => import("/app/pages/planning/vembauche/index.vue")
  },
  {
    name: "adhesion-traitements-id",
    path: "/adhesion/traitements/:id()",
    meta: _91id_93PmXFf2lSm7TkpJjr3Y6nOe_45X9FrdQRV4Xagt0s7Or7QMeta || {},
    component: () => import("/app/pages/adhesion/traitements/[id].vue")
  },
  {
    name: "fdr-point-point-idboite",
    path: "/fdr/point/point/:idboite()",
    meta: _91idboite_93kA48tIUmq7_45_45Nj4nWt27rE7XXlqvmHBuy6Oe10RClVYMeta || {},
    component: () => import("/app/pages/fdr/point/point/[idboite].vue")
  },
  {
    name: "planning-formations",
    path: "/planning/formations",
    meta: indexkkw1wu60EnwzN_45YWzvsTcI5JyYBDC7Rwjw_45a_K72kkYMeta || {},
    component: () => import("/app/pages/planning/formations/index.vue")
  },
  {
    name: "planning-persuasion",
    path: "/planning/persuasion",
    meta: indexHnTlGMiUcIvcCVd2jBfgmo_45QFHQ_45LDfa_45C009PMzfOEMeta || {},
    component: () => import("/app/pages/planning/persuasion/index.vue")
  },
  {
    name: "reglement-interieur-ostie",
    path: "/reglement-interieur-ostie",
    component: () => import("/app/pages/reglement-interieur-ostie.vue")
  },
  {
    name: "suggestions-affilie",
    path: "/suggestions-affilie",
    meta: indexXlICEcyGA6QOJcUfPwSde0ltlmI5o9YkHjVJkv_450gyQMeta || {},
    component: () => import("/app/pages/suggestions-affilie/index.vue")
  },
  {
    name: "tableau-bord-reclamations",
    path: "/tableau-bord-reclamations",
    component: () => import("/app/pages/tableau-bord-reclamations.vue")
  },
  {
    name: "adhesion-traitements",
    path: "/adhesion/traitements",
    meta: indexEBXzYlrJC46zixsU5jTaeM52OCI2RdvT6lwbnZK3JjMMeta || {},
    component: () => import("/app/pages/adhesion/traitements/index.vue")
  },
  {
    name: "dns-fichiers-fichierFinder",
    path: "/dns/fichiers/fichierFinder",
    component: () => import("/app/pages/dns/fichiers/fichierFinder.vue")
  },
  {
    name: "edition-fdr-articles",
    path: "/edition-fdr/articles",
    meta: indexMfvBPs8Cpn6gsvzNSOde9_Oe76QyFHbCR5hellgGKc0Meta || {},
    component: () => import("/app/pages/edition-fdr/articles/index.vue")
  },
  {
    name: "fdr-point-thematique",
    path: "/fdr/point/thematique",
    meta: indexPFIcHmSiT82_UJKikJkkkM5iJSqmoQ15Cj4B5lOaT2cMeta || {},
    component: () => import("/app/pages/fdr/point/thematique/index.vue")
  },
  {
    name: "fdr-tableau-idthematique",
    path: "/fdr/tableau/:idthematique()",
    meta: _91idthematique_93KKWWM4siIn9Q5SrVRmJi091Y6mcJ_51EFYlaUGp1mbAMeta || {},
    component: () => import("/app/pages/fdr/tableau/[idthematique].vue")
  },
  {
    name: "reclamations-affilie",
    path: "/reclamations/affilie",
    meta: indexgcCFR7Eo2cFe_ScPuu5urpObhrlzCDmRjl_45x_45fSzedwMeta || {},
    component: () => import("/app/pages/reclamations/affilie/index.vue")
  },
  {
    name: "users-assigner-active-id",
    path: "/users/assigner/active/:id()",
    meta: _91id_93iRkqJfQpDigyeh3KiG8h_45_9Yxs5r1YysgMJiy4xmuxsMeta || {},
    component: () => import("/app/pages/users/assigner/active/[id].vue")
  },
  {
    name: "avis-embauche-debauche-id",
    path: "/avis-embauche-debauche/:id()",
    meta: _91id_93mG5W2ZLrwn87ezbjr9Jpo4iRXcf_4B0Hm9Xpp4j0Ze0Meta || {},
    component: () => import("/app/pages/avis-embauche-debauche/[id].vue")
  },
  {
    name: "bulletin-consultation",
    path: "/bulletin-consultation",
    meta: indexB2FVtkCUSZNxxoFUiLQSx__4DNAF27RVwaedfuMochIMeta || {},
    component: () => import("/app/pages/bulletin-consultation/index.vue")
  },
  {
    name: "fdr-create-thematique",
    path: "/fdr/create/thematique",
    meta: indexenKsZSS0w98QVy1xZovD2pKnzqviP2oy_45mn1e7ejMg0Meta || {},
    component: () => import("/app/pages/fdr/create/thematique/index.vue")
  },
  {
    name: "fdr-read-diagnostic-idfdr",
    path: "/fdr/read/diagnostic/:idfdr()",
    meta: _91idfdr_93e8Mtb_456IwH_45a9ah_4bY1n6c6oD_45sL2kHxg8vV1YelW4Meta || {},
    component: () => import("/app/pages/fdr/read/diagnostic/[idfdr].vue")
  },
  {
    name: "avis-embauche-debauche",
    path: "/avis-embauche-debauche",
    meta: indexahAEny2f9Zmwn6Sigv9i9lKYl5P2H8E4NEQ54h_npCIMeta || {},
    component: () => import("/app/pages/avis-embauche-debauche/index.vue")
  },
  {
    name: "planning-autres-visites-id",
    path: "/planning/autres-visites/:id()",
    component: () => import("/app/pages/planning/autres-visites/[id].vue")
  },
  {
    name: "reclamations-archive-in-id",
    path: "/reclamations/archive-in/:id()",
    meta: _91id_93fUIDUyHW0_gfbZ3YTN0C6HDuKFtEsf0gsGUl7CfsY9MMeta || {},
    component: () => import("/app/pages/reclamations/archive-in/[id].vue")
  },
  {
    name: "edition-fdr-boite-risque-id",
    path: "/edition-fdr/boite-risque/:id()",
    meta: _91id_93F9A9_Wb2glqKlGZyEgrL_SwxB7h2ZOk0DCzueDvXvJcMeta || {},
    component: () => import("/app/pages/edition-fdr/boite-risque/[id].vue")
  },
  {
    name: "fdr-create-diagnostic-idfdr",
    path: "/fdr/create/diagnostic/:idfdr()",
    meta: _91idfdr_93eg7MwtcXib_krgHRE3U0dBrgiCfF8Hof40YHTdPNcxMMeta || {},
    component: () => import("/app/pages/fdr/create/diagnostic/[idfdr].vue")
  },
  {
    name: "planning-autres-visites",
    path: "/planning/autres-visites",
    meta: indexlc9X9gPU1l4jwKrHs4DGdr1t_TdUbhFv3_9bpDSxynYMeta || {},
    component: () => import("/app/pages/planning/autres-visites/index.vue")
  },
  {
    name: "reclamations-archive-in",
    path: "/reclamations/archive-in",
    meta: indexjB_rQ9alDrxPdeIHoYIGCrvIX8fTkPc8OQ4QnciBX6EMeta || {},
    component: () => import("/app/pages/reclamations/archive-in/index.vue")
  },
  {
    name: "planning-sensibilisation",
    path: "/planning/sensibilisation",
    meta: indexOKcAPBZueM_rLgJzEu6jONv2yRLmLQSGKOjGxR1bgPQMeta || {},
    component: () => import("/app/pages/planning/sensibilisation/index.vue")
  },
  {
    name: "users-component-passwordChange",
    path: "/users/component/passwordChange",
    component: () => import("/app/pages/users/component/passwordChange.vue")
  },
  {
    name: "adhesion-traitements-files-id",
    path: "/adhesion/traitements/files/:id()",
    meta: _91id_93YUrPLZdH2cf2_oPZNFwBWdNGtO4Ku_45yVMhOGJxWSaRMMeta || {},
    component: () => import("/app/pages/adhesion/traitements/files/[id].vue")
  },
  {
    name: "edition-fdr-facteur-risque-id",
    path: "/edition-fdr/facteur-risque/:id()",
    meta: _91id_93WYYrn2Q7_45HYShWgBIEPjgZgLM7csXddrcCIBkKma8EUMeta || {},
    component: () => import("/app/pages/edition-fdr/facteur-risque/[id].vue")
  },
  {
    name: "planning-vembauche-traiter-id",
    path: "/planning/vembauche/traiter/:id()",
    meta: _91id_933hLuCKVRw79BgJ8sRJ7YqTnysf2d_452DGqs3Ln4zMWSoMeta || {},
    component: () => import("/app/pages/planning/vembauche/traiter/[id].vue")
  },
  {
    name: "contenus-reglement-adherent-id",
    path: "/contenus/reglement-adherent/:id()",
    meta: _91id_93ldANiEKU40Va1F568_45rG8_45Ye1cijqQY82SWqpsLiXkoMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/[id].vue")
  },
  {
    name: "planning-vembauche-traiter",
    path: "/planning/vembauche/traiter",
    meta: index7WkBwpH5AictgYez2x9m7h0BV5qGJ3phSSYj5bd56VgMeta || {},
    component: () => import("/app/pages/planning/vembauche/traiter/index.vue")
  },
  {
    name: indexiBdJKoE29NSwe8FNOcfatJoxKEaMARoJrVevDE68ZgEMeta?.name,
    path: "/contenus/reglement-adherent",
    meta: indexiBdJKoE29NSwe8FNOcfatJoxKEaMARoJrVevDE68ZgEMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/index.vue"),
    children: [
  {
    name: "contenus-reglement-adherent",
    path: "",
    meta: index_40old_41ln_7Ky3nYuKLiP9uqxe4ulgmPdXCgxOa8ZGe2nOH_45XAMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/index(old).vue")
  }
]
  },
  {
    name: "edition-fdr-articles-create",
    path: "/edition-fdr/articles/create",
    meta: indexC3sCYEP_45DoDvlmf0kYrrKfGrmbvSuf7OeYollK6V8ccMeta || {},
    component: () => import("/app/pages/edition-fdr/articles/create/index.vue")
  },
  {
    name: "planning-accompagnement-appuie-id",
    path: "/planning/accompagnement-appuie/:id()",
    component: () => import("/app/pages/planning/accompagnement-appuie/[id].vue")
  },
  {
    name: "fdr-diagnostic-create-iddiagnostic",
    path: "/fdr/diagnostic/create/:iddiagnostic()",
    meta: _91iddiagnostic_93_l_1LhOMEXJ3nc8wqL4JVyHNfL0ljnZsDO7aT6LDaTEMeta || {},
    component: () => import("/app/pages/fdr/diagnostic/create/[iddiagnostic].vue")
  },
  {
    name: "planning-accompagnement-appuie",
    path: "/planning/accompagnement-appuie",
    meta: indexkafEcBDKlAUxS6mitPKfk5whn7b7_45o50Scfl19agE4kMeta || {},
    component: () => import("/app/pages/planning/accompagnement-appuie/index.vue")
  },
  {
    name: "edition-fdr-facteur-diagnostique-id",
    path: "/edition-fdr/facteur-diagnostique/:id()",
    meta: _91id_93mMiTOOusnwJlUY1iBsyV9u45P0pxilnW7saaZ19R_45kEMeta || {},
    component: () => import("/app/pages/edition-fdr/facteur-diagnostique/[id].vue")
  },
  {
    name: "fdr-diagnostic-idthematique-idfdr",
    path: "/fdr/diagnostic/:idthematique()-:idfdr()",
    meta: _91idthematique_93_45_91idfdr_93IjlByVwgKamQ1_45nlLtrlsNiQ_452mvFNeAle3jB0f19pYMeta || {},
    component: () => import("/app/pages/fdr/diagnostic/[idthematique]-[idfdr].vue")
  },
  {
    name: "contenus-reglement-adherent-poster-id",
    path: "/contenus/reglement-adherent/poster/:id()",
    meta: _91id_93IE45AZiqW5pHfICaPnjW463_45bSiJHL1JbDpLQvkmpAIMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/poster/[id].vue")
  },
  {
    name: "contenus-reglement-adherent-poster",
    path: "/contenus/reglement-adherent/poster",
    meta: index8pAkQpi7EzGW8qNZJGeWMO4vy0JRpx7fiRNVll5X0DYMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/poster/index.vue")
  },
  {
    name: "fdr-diagnostic-resiliation-iddiagnostic",
    path: "/fdr/diagnostic/resiliation/:iddiagnostic()",
    meta: _91iddiagnostic_938FYOVlz79T5si6chPFDAZO3R_yDCLYh7yIrD_45sRm6n0Meta || {},
    component: () => import("/app/pages/fdr/diagnostic/resiliation/[iddiagnostic].vue")
  },
  {
    name: "contenus-reglement-adherent-archive-in-id",
    path: "/contenus/reglement-adherent/archive-in/:id()",
    meta: _91id_93T_45Z0ypApyBqueom9Tq2zoCCClK8HVsGbt8JdvfyAdFMMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/archive-in/[id].vue")
  },
  {
    name: "avis-embauche-debauche-historique-ae-ed-id",
    path: "/avis-embauche-debauche/historique-ae-ed/:id()",
    meta: _91id_93FRK87SUXyF9RzEAIRdVIv9E4lE_45cR9k0U2VpqbFOB9gMeta || {},
    component: () => import("/app/pages/avis-embauche-debauche/historique-ae-ed/[id].vue")
  },
  {
    name: "contenus-reglement-adherent-archive-in",
    path: "/contenus/reglement-adherent/archive-in",
    meta: indexYjVmxffUEHse6W3Bvzu7xCX_UYfibSzKANiw_TzIT_sMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/archive-in/index.vue")
  },
  {
    name: "avis-embauche-debauche-historique-ae-ed",
    path: "/avis-embauche-debauche/historique-ae-ed",
    meta: indexT49_45eXl1V7OJo0wGDJ0VlH2hERnhMctbDpTdZhu9ixEMeta || {},
    component: () => import("/app/pages/avis-embauche-debauche/historique-ae-ed/index.vue")
  },
  {
    name: "contenus-reglement-adherent-convocations",
    path: "/contenus/reglement-adherent/convocations",
    meta: indexyVmU226mIkncgNPQ5JVsGxnunnFjba9xM_45mSvEVaDLYMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/convocations/index.vue")
  },
  {
    name: "contenus-reglement-adherent-convocations-new",
    path: "/contenus/reglement-adherent/convocations/new",
    meta: indexK2CEiWlqU5M9CKBQ1CMU0soxJsPlGqqHzEhrSfbhoycMeta || {},
    component: () => import("/app/pages/contenus/reglement-adherent/convocations/new/index.vue")
  }
]