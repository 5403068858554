import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import axios_nKQInGxkjkGyJUCE2J7W14opqRZSZPNVns2t0UtSgnM from "/app/plugins/axios.ts";
import calendar_LFghU2FyvGKro4ipUaOFWtFEiRiVx03zgHXxc6X0yrs from "/app/plugins/calendar.ts";
import casl_VPXiNH6AHjTxsev0mxsdcqCYt6yr6eezuXo6J48kuLU from "/app/plugins/casl.js";
import socket_MsDhSnEGwKOYByF_WEim8gnmubZLDyf_aUmnTDRUoj4 from "/app/plugins/socket.js";
import plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4 from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  axios_nKQInGxkjkGyJUCE2J7W14opqRZSZPNVns2t0UtSgnM,
  calendar_LFghU2FyvGKro4ipUaOFWtFEiRiVx03zgHXxc6X0yrs,
  casl_VPXiNH6AHjTxsev0mxsdcqCYt6yr6eezuXo6J48kuLU,
  socket_MsDhSnEGwKOYByF_WEim8gnmubZLDyf_aUmnTDRUoj4,
  plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4
]